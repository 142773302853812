export const CHAIN = {
  Ethereum: "ethereum",
  Arbitrum: "arbitrum",
  Avalanche: "avalanche",
  Fantom: "fantom",
  BSC: "binancesmartchain",
  Polygon: "polygon",
  Optimism: "optimism",
  Klaytn: "klaytn",
  Gnosis: "gnosis",
  CronosEVM: "cronosevm",
  Aurora: "aurora",
  CantoEVM: "cantoevm",
  Sepolia: "sepolia",
  zkSync: "zksync",
  Linea: "linea",
  Blast: "blast",
  Mantle: "mantle",
  Beam: "beam",
  Celo: "celo",
  Manta: "manta",
  opBNB: "opbnb",
  ZetaChain: "zetachain",

  BinanceChain: "bnbc",
  Near: "near",

  Bitcoin: "bitcoin",
  Litecoin: "litecoin",
  BitcoinCash: "bitcoincash",
  Dogecoin: "dogecoin",
  Tron: "tron",

  Solana: "solana",
  ThorChain: "thorchain",
  MayaChain: "mayachain",

  CosmosHub: "cosmoshub",
  Osmosis: "osmosis",
  Axelar: "axelar",
  Stride: "stride",
  Mars: "mars",
  Stargaze: "stargaze",
  Crescent: "crescent",
  Kujira: "kujira",
  CryptoOrg: "cryptoorg",
  Kava: "kava",
  Akash: "akash",
  Juno: "juno",

  Terra: "terra",
} as const;

export type ChainName = keyof typeof CHAIN;
export type ChainKey = (typeof CHAIN)[ChainName];

export type NativeToken = {
  symbol: string;
  decimals: number;
  actions: string[];
};

export type Token = NativeToken & {
  address: string;
};

export type ChainInfo = {
  name: string;
  key: ChainKey;
  chainId: number | string;
  native: NativeToken;
  tokens: Token[];
};
